.info-box-1 {
	.box {
		padding-top: 30px;
		padding-bottom: 30px;

	}
}

.card {
	transition: all 0.2s;

	&:focus {
		outline: none;
	}
}

.info-box-3 {
	max-width: 972px;

	.card {
		margin-left: -1px;
	}

	.card-img-top {
		border-color: rgba(255, 255, 255, 0.4) !important;
		max-height: 68px;
	}

	.card:hover {
		.card-title {
			color: $dark !important;
		}
	}


}

.info-box-4 {
	.card-body {
		span {
			height: 32px;
			opacity: 0;
			transition: all 0.5s;
		}

		.card-title {
			@include media-breakpoint-up(sm) {
				margin-left: 42px;
			}
			transition: all 0.5s;
		}
	}

	.card:hover {
		.card-body span {
			opacity: 1;
		}

		.card-title {
			margin-left: 0;
		}
	}
}