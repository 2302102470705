// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font_family_primary: 'Libre Baskerville', serif;

$font_size-body: 0.875rem;

$font-weight-500: 500;
$font-weight-600: 600;

$font-sises: (
		'12':12px,
		'13':13px,
		'14':14px,
		'15':15px,
		'16':16px,
		'17':17px,
		'18':18px,
		'20':20px,
		'22':22px,
		'23':23px,
		'24':24px,
		'25':25px,
		'26':26px,
		'30':30px,
		'32':32px,
		'34':34px,
		'35':35px,
		'36':36px,
		'40':40px,
		'42':42px,
		'44':44px,
		'45':45px,
		'48':48px,
		'52':52px,
		'54':54px,
		'56':56px,
		'60':60px,
		'64':64px,
		'65':65px,
		'90':90px,
		'109':109px,
		'200':200px,
		'250':250px
);

$line-heights: (
		1:1,
		114:1.14,
		12:1.2,
		13:1.3,
		143:1.43,
		15:1.5,
		16:1.6,
		1625:1.625,
		163:1.63,
		164:1.64,
		17:1.7,
		173:1.73,
		182:1.82,
		184:1.84,
		186:1.86,
		187:1.87,
		188:1.88,
		192:1.92,
		194:1.94,
		2:2,
		213:2.13,
		214:2.14,
		22:2.2,
		238:2.38,
		26:2.6,
		29:2.9
);
$opacity: (
		0:0,
		1:0.1,
		2:0.2,
		3:0.3,
		4:0.4,
		5:0.5,
		6:0.6,
		7:0.7,
		8:0.8,
		9:0.9,
		10:1,
);
$z-index: (
		1:1,
		2:2,
		3:3,
		4:4,
		5:5,
		6:6,
		7:7,
		8:8,
		9:9,
		10:10,
);
$bg-gradient: (
		1:linear-gradient(rgba(0, 0, 0, 0) 76%, #000000 108%),
		2:linear-gradient(rgba(0, 0, 0, 0), #000000),
		3:linear-gradient(rgba(0, 0, 0, 0) 5%, #000000 109%),
		4:linear-gradient(rgba(0, 0, 0, 0) 11%, #000000 99%),
		5:linear-gradient(rgba(0, 0, 0, 0) 20%, #000000 125%),
		6:linear-gradient(rgba(0, 0, 0, 0) 42%, #000000),
		7:linear-gradient(rgba(0, 0, 0, 0) 72%, #000000 109%),
		8:linear-gradient(rgba(0, 0, 0, 0) 50%, #000000 125%),
);

$box-shadow-xxs-1: 0 0 7px 0 rgba(0, 0, 0, 0.07);
$box-shadow-xxs-2: 0 0 10px 0 rgba(51, 51, 51, 0.1);
$box-shadow-xxs-3: 0 0 10px 0 rgba(51, 51, 51, 0.15);
$box-shadow-xxs-4: 0 0 20px 0 rgba(51, 51, 51, 0.1);
$box-shadow-xxs-5: 0 0 20px 0 rgba(51, 51, 20, 0.1);
$box-shadow-xs-1: 0 2px 15px 0 rgba(51, 51, 51, 0.05);
$box-shadow-xs-2: 0 5px 15px 0 rgba(51, 51, 51, 0.08);
$box-shadow-xs-3: 0 11px 19px 0 rgba(51, 51, 51, 0.15);
$box-shadow-xs-4: 0 11px 30px 0 rgba(51, 51, 51, 0.15);
$box-shadow-xs-5: 0 2px 15px 0 rgba(51, 51, 51, 0.1);
$box-shadow-xs-6: 0 2px 16px 0 rgba(14, 198, 213, 0.34);
$box-shadow-sm-1: 0 5px 20px 0 rgba(51, 51, 51, 0.08);
$box-shadow-sm-2: 0 5px 30px 0 rgba(51, 51, 51, 0.2);
$box-shadow-1: 0 11px 30px 0 rgba(51, 51, 51, 0.15);
$box-shadow-2: 0 20px 15px 0 rgba(51, 51, 51, 0.03);
$box-shadow-3: 0 20px 30px 0 rgba(51, 51, 51, 0.06);
$box-shadow-4: 0 19px 34px 0 rgba(51, 51, 51, 0.06);
$box-shadow-5: 0 7px 15px 0 rgba(51, 51, 51, 0.05);
$box-shadow-lg-1: 0 30px 50px 0 rgba(51, 51, 51, 0.1);
$box-shadow-lg-2: 0 18px 40px 0 rgba(51, 51, 51, 0.1);
$box-shadow-lg-3: 0 14px 59px 0 rgba(0, 0, 0, 0.12);
$box-shadow-lg-4: 0 19px 34px 0 rgba(94, 122, 183, 0.14);
$box-shadow-lg-5: 0 0 29px 0 rgba(0, 0, 0, 0.05);
$box-shadow-lg-6: 0 15px 30px 0 rgba(0, 0, 0, 0.05);

$box-shadows: (
		'xxs-1':$box-shadow-xxs-1,
		'xxs-2':$box-shadow-xxs-2,
		'xxs-3':$box-shadow-xxs-3,
		'xxs-4':$box-shadow-xxs-4,
		'xxs-5':$box-shadow-xxs-5,
		'xs-1':$box-shadow-xs-1,
		'xs-2':$box-shadow-xs-2,
		'xs-3':$box-shadow-xs-3,
		'xs-4':$box-shadow-xs-4,
		'xs-5':$box-shadow-xs-5,
		'xs-6':$box-shadow-xs-6,
		'sm-1': $box-shadow-sm-1,
		'sm-2': $box-shadow-sm-2,
		'1':$box-shadow-1,
		'2':$box-shadow-2,
		'3':$box-shadow-3,
		'4':$box-shadow-4,
		'5':$box-shadow-5,
		'lg-1':$box-shadow-lg-1 ,
		'lg-2': $box-shadow-lg-2,
		'lg-3': $box-shadow-lg-3,
		'lg-4': $box-shadow-lg-4,
		'lg-5': $box-shadow-lg-5,
		'lg-6': $box-shadow-lg-6,
);
$letter-spacings: (
		'02':0.2px,
		'023':0.23px,
		'087':0.87px,
		'093':0.93px,
		'1':1px,
		'163':1.63px,
		'173':1.73px,
		'263':2.63px,
		'367':3.67px,
		'34':3.4px,
		'5':5px
);

$heading-color: #333;
$lighter: #dadada;
$gray-lighter: #b3b3b3;
$gray-light: #9b9b9b;
$gray: #7e7e7e;
$green-1: #eff6f7;