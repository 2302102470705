@include media-breakpoint-up(lg) {
	.cusstom-bg-slider-gray:before {
		content: '';
		position: absolute;
		background-color: #f3f6fa;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: -1;
		max-width: 770px;
		margin-left: auto;
		width: 100%;
	}
}

.bg-cover{
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}