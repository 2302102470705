.pagination.rounded-active {
	.page-item {
		.page-link {
			display: flex;
			align-items: center;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			justify-content: center;
		}

	}


	.page-item {
		font-size: 16px;
		font-weight: 600;

		&:not(:last-child) {
			margin-right: 15px;
		}
	}
}