.galleries {
	.item {
		position: relative;
		height: 100%;
	}

	.card {
		border: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}


	.card-img {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		position: relative;
		border-radius: 5px;
	}

	.item-size-1-1:after {
		content: '';
		display: block;
		padding-top: 100%;
	}

	.item-size-2-1:after {
		content: '';
		display: block;
		padding-top: 50%;
	}

	.item-size-4-3:after {
		content: '';
		display: block;
		padding-top: 75%;
	}

	.item-size-3-2:after {
		content: '';
		display: block;
		padding-top: 53%;
	}

	.custom-size-1 {
		&:after {
			content: '';
			display: block;
			padding-top: 62%;
		}
	}


	.slider-nav {
		.box {
			cursor: pointer;
		}
	}
}

.button-video-white:hover {
	border-color: $white !important;

	span {
		background-color: $primary !important;
		color: $white !important;
	}

}

@include media-breakpoint-down(sm) {
	.button-video-white {
		span {
			width: 52px !important;
			height: 52px !important;
		}

	}
}

.nav-gallery {
	.nav-link.active {
		background-color: $primary !important;
		color: $white !important;
	}
}