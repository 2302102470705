.dropright .dropdown-toggle::after,
.dropdown-toggle::after {
	content: "\f107";
	font-family: "Font Awesome 5 Pro";
	font-weight: 300;
	border: 0;
	vertical-align: 0;

}

.dropright {
	.dropdown-toggle::after {
		content: "\f105";
	}

	.dropdown-link {
		display: flex;

		&::after {
			margin-left: auto;
		}
	}
}

.dropdown-item a {
	color: inherit;
	text-decoration: none;
}

.dropdown-sm {
	min-width: 7rem;
}

.dropdown-menu-md {
	min-width: 20rem;
}

.dropdown-menu-lg {
	min-width: 30rem;
}

.dropdown-menu-xl {
	min-width: 40rem;
}

.dropdown-menu-xxl {
	min-width: 50rem;
}

.dropdown-menu-full {
	width: 100%;
}

.dropdown-header {
	display: inline-block;
	@include font-size($font-size-base);
}

.no-caret {
	.dropdown-toggle::after {
		display: none;
	}
}

.sortby div.dropdown-menu {
	left: auto !important;
	right: 0 !important;
}