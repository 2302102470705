.slick-slider {
	margin-left: -15px;
	margin-right: -15px;

	.box {
		background-color: transparent !important;
		font-size: $font-size-base;

		&:focus {
			outline: none;
		}
	}

	img {
		max-width: 100%;
		width: auto;
	}

	:focus {
		outline: none;
	}

}

.slick-slide {
	height: inherit;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
}

.slick-track {
	display: flex;
}

.slick-vertical {
	height: auto;

	.slick-track {
		display: block;
	}

	.slick-dots {
		right: 50px;
		left: auto;
		top: 50%;
		transform: translateY(-50%);

		li {
			&:not(:last-child) {
				margin-bottom: 30px;
			}
		}
	}
}

.slick-arrow {
	text-align: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: $white;
	width: 52px;
	height: 52px;
	box-shadow: $box-shadow-xxs-1;
	line-height: 52px;
	font-size: 28px;
	border-radius: 50%;
	color: $body-color;
	z-index: 100;

	i {
		font-weight: 300;
	}

	&:hover {
		color: $white;
		background-color: $primary;
	}

	&.slick-disabled {
		cursor: no-drop;
	}

	&.slick-prev {
		left: 0;
		right: auto;
	}

	&.slick-next {
		left: auto;
		right: 0;
	}

	i {
		line-height: inherit;
	}

	cursor: pointer;
}

.arrow-haft-inner {
	.slick-prev {
		left: -26px;
		right: auto;
	}

	.slick-next {
		right: -26px;
		left: auto;
	}
}

.arrow-inner {
	.slick-prev {
		left: 30px;
		right: auto;
	}

	.slick-next {
		right: 30px;
		left: auto;
	}
}

.arrow-outer {
	.slick-prev {
		left: -82px;
		right: auto;
	}

	.slick-next {
		right: -82px;
		left: auto;
	}
}

.arrow-hide-disable {
	.slick-disabled {
		display: none !important;
	}
}

.slick-dots {
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 0;
	margin-bottom: 0;
	margin-top: 30px;

	li {
		cursor: pointer;
		text-align: center;
		padding: 0 15px;
	}

	span {
		display: inline-block;
		line-height: 12px;
		width: 8px;
		height: 8px;
		background: #333;
		opacity: 0.3;
		border-radius: 50%;
	}
}

.slick-dots li.slick-active,
.slick-dots li:hover,
.slick-arrow:active,
.slick-dots li:active,
.slick-arrow:focus,
.slick-dots li:focus {
	opacity: 1;
}

.slick-dots li.slick-active span {
	background: $headings-color;
	opacity: 1;
}

.dots-white {
	.slick-dots {
		margin-top: -30px;

		span {
			background: $white;
			opacity: 0.5;
		}

		li.slick-active span {
			background: $white;
		}
	}
}

.custom-slider-1 {
	.slick-arrow {
		bottom: 0;
		right: 0;
		top: auto;
		left: auto;
		transform: translateY(0);
		box-shadow: none;
		color: #333;
		font-size: 28px;

		&:hover {
			color: $white;
		}
	}

	.slick-prev {
		left: auto;
		right: 62px;
	}

	.slick-next {
		right: 0;
		left: auto;
	}

	.slick-dots {
		justify-content: flex-start;

		li:not(.slick-active) {
			display: none;
		}

		.slick-active {
			display: flex;
			align-items: center;
			padding: 0;
		}

		.dot, .dot-divider {
			height: auto;
			width: auto;
			background-color: transparent;
		}

		.dot {
			font-size: 15px;
			font-weight: 600;
			color: $heading-color;
		}

		.dot-divider {
			display: inline-block;
			content: '';
			width: 50px;
			height: 1px;
			border-bottom: 1px solid $text-muted;
			margin: 0 15px;
		}

	}
}

.custom-vertical {

	margin: 0;

	.slick-slide {
		position: relative;
	}

	.slick-slide.slick-cloned {
		z-index: -1;
	}

	.slick-slide:not(.slick-active) {
		opacity: 0.4;

		.card {
			transform: scale(0.8);
			margin: 0 auto;
			transition: all 0.2s;
		}
	}

	.slick-slide.slick-active {
		z-index: 2;
		height: auto;
	}

	.slick-slide.prev {
		transform: translateY(100%);
		top: -110px;
		z-index: 1;
	}

	.slick-slide.slick-active + .slick-slide {
		transform: translateY(-100%);
		bottom: -120px;
		z-index: 1;
	}

}

.custom-slider-2 {
	.slick-list {
		padding-right: 24.3% !important;
		@include media-breakpoint-down(sm) {
			padding-right: 0 !important;
		}
	}

	.slick-active ~ * {
		opacity: 0.3;
	}

	.slick-arrow {
		bottom: -13px;
		right: 0;
		top: auto;
		left: auto;
		transform: translateY(0);
		box-shadow: none;
		color: #333;
		font-size: 28px;
		border: solid 1px #e7e7e7;

		&:hover {
			color: $white;
		}
	}

	.slick-prev {
		left: 40%;
		right: auto;
		@include media-breakpoint-down(md) {
			left: 35%;
		}
		@include media-breakpoint-down(sm) {
			left: 25%;
		}
	}

	.slick-next {
		right: 40%;
		left: auto;
		@include media-breakpoint-down(md) {
			right: 35%;
		}
		@include media-breakpoint-down(sm) {
			right: 25%;
		}
	}

	.card {
		height: 100%;
	}

	.slick-dots {
		margin-top: 0;

		li:not(.slick-active) {
			display: none;
		}

		.dot {
			font-size: 15px;
			font-weight: 600;
			color: $heading-color;
			width: auto;
			height: auto;
			background-color: transparent;
		}

	}
}

.custom-slick-slide-pd-20 {
	.slick-slide {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (min-width: 1441px) {
	.custom-arrow-spacing-30 {
		.slick-arrow {
			&.slick-next {
				right: -70px;
			}

			&.slick-prev {
				left: -70px;
			}
		}
	}
}

@media (max-width: 1440px) and (min-width: 1200px) {
	.custom-arrow-spacing-30 {
		.slick-arrow {
			display: none !important;
		}
	}

	.custom-arrow-xxl-hide {
		.slick-arrow {
			display: none !important;
		}
	}
}

.slick-dots-mt-0 .slick-dots {
	margin-top: 0 !important;
}

.custom-dots {
	.slick-dots {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $secondary;
		padding: 28px 50px;
		justify-content: space-between;

		li {
			font-size: 32px;
			font-weight: 500;
			color: #ffffff;
			opacity: 0.5;

			&.slick-active {
				opacity: 1;
			}
		}
	}
}

@media (min-width: 1200px) {
	.custom-arrow-center {
		.slick-arrow {
			right: 50%;
			transform: translateX(50%);
			margin-top: 165px;
			margin-right: 68px;
			left: auto;
			width: 32px;
			height: 32px;
			font-size: 18px;
			line-height: 32px;
		}

		.slick-arrow.slick-next {
			margin-right: 25px;
		}

		.slick-dots {
			max-width: 1170px;
			margin-left: auto;
			margin-right: auto;
			justify-content: start;
			padding-left: 100px;
			position: absolute;
			left: 0;
			right: 0;
			transform: translateY(50%);
			margin-top: 232px;
			top:50%;
			span {
				background: #fff;
			}
			&.slick-active span{
				opacity: 1;
			}
		}
	}
}

@media (min-width: 992px) {
	.item-nth-2-active-lg:not(:hover) {
		.slick-active:nth-child(2) > div {
			box-shadow: $box-shadow-xs-4 !important;

			.hover-image {
				opacity: 1 !important;
			}
		}
	}
}

@media (max-width: 575px) {
	.custom-dots {
		.slick-dots {
			padding: 20px 30px;

			li {
				font-size: 18px;
			}
		}
	}
}

.calendar {
	.slick-arrow {
		width: 40px;
		height: 40px;
		line-height: 40px;
	}

	.slick-prev {
		left: -20px;
		right: auto;
	}

	.slick-next {
		right: -20px;
		left: auto;
	}
}

.custom-slider-arrow-20 {
	.slick-prev {
		left: 35px;
	}

	.slick-next {
		right: 35px;
	}

	.slick-dots {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		bottom: 18px;

		span {
			background: #fff;
		}
	}
}

.custom-dots-center{
	.slick-dots{
		margin-top: 70px;
		justify-content: center;
		.dot{
			font-size: 20px;
			font-weight: 500;
			color: $secondary;
		}
	}
	&.text-heading{
		.slick-dots{
			.dot{
				color: $heading-color;
			}
		}
	}
}

.custom-vh-100 {
	min-height: 100vh;
}

.custom-vh-01 {
	min-height: calc(100vh - 97px);
}

.custom-vh-02 {
	min-height: calc(100vh - 94px);
}

.custom-vh-03 {
	min-height: calc(100vh - 194px);
}

.custom-vh-04 {
	min-height: calc(100vh - 154px);
}

@media (max-width: 991px) {
	.custom-vh-01,
	.custom-vh-02,
	.custom-vh-100 {
		min-height: calc(100vh - 72px);
	}
	.custom-vh-03,
	.custom-vh-04 {
		min-height: calc(100vh - 184px);
	}
}

@media (min-width: 576px) {
	.custom-slider-arrow-top {
		.slick-arrow {
			background-color: transparent;
			box-shadow: none;
			width: auto;
			height: auto;
			transform: none;
			right: 15px;
			top: -65px;
			font-size: 35px;

			&.slick-next {
				right: 15px;
			}

			&.slick-prev {
				right: 45px;
				left: auto;
			}

			&:hover {
				color: $primary;
			}
		}
	}
}
