.nav-link {
	font-weight: 500;
}
.nav-tabs {
	.nav-link {
		background-color: #eee;
		color: inherit;
		padding: 0.5rem 1.875rem;
	}
	.nav-link.active {
		box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.1);
		position: relative;
	}
	.nav-link.active:before {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		height: 11px;
		left: 0;
		width: 100%;
		background-color: #fff;
		z-index: 1;
	}
	.nav-item:not(.active) + .nav-item {
		margin-left: 3px;
	}
}
.tab-content {
	padding: 30px;
	box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.1);
	border-radius: 0 0.3125rem 0.3125rem 0.3125rem;
}
.nav-pills-01{
	.nav-link {
		color: rgba(51, 51, 51, 0.4);
		position: relative;

		&::before {
			display: none;
		}

		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			top: auto;
			width: 0;
			height: 2px;
			background-color: $primary;
			transition: all 0.5s;
		}

		&:hover, &.active {
			color: #333;

			&::after {
				width: 90%;
			}
		}
	}
}
