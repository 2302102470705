.bg-single-image {
	background-image: url(../images/single-image-02.png), url(../images/single-image-03.png);
	background-position: left, right bottom;
	background-repeat: no-repeat, no-repeat;
	background-size: auto, auto;
}

.bg-properties-creative {
	background-image: url(../images/properties-creative-01.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-single-image-02 {
	background-image: url(../images/single-image-04.png);
	background-position: right;
	background-repeat: no-repeat;
	background-size: auto;
}

.bg-single-image-03 {
	background-image: url(../images/BG7.png), url(../images/BG8.png);
	background-position: left, right bottom;
	background-repeat: no-repeat, no-repeat;
	background-size: auto, auto;
}

.bg-landing-listing {
	background-color: #e9edf2;
}

@media (min-width: 992px) {
	.bg-landing-listing {
		background-image: url(../images/bg-listing-landing-left.png), url(../images/bg-listing-landing-right.png);
		background-position: left center, right center;
		background-repeat: no-repeat, no-repeat;
		background-size: auto, auto;
	}
}
