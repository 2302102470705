.dashboard-wrapper {
	.main-header {
		.badge-absolute {
			top: 20px;
			right: 17px;
		}

		.navbar {
			@include media-breakpoint-down(lg) {
				background-color: transparent !important;
			}

		}

		@include media-breakpoint-down(lg) {
			position: static !important;
			.dropdown-menu {
				position: absolute;
			}
		}
	}

	.db-sidebar {
		flex: 0 1 320px;
		max-width: 320px;
		@include media-breakpoint-down(lg) {
			flex: 1 1 100%;
			max-width: 100%;
		}


		.sidebar-item {
			.sidebar-item-number {
				opacity: 0;
			}

			&:hover, &.active {
				background-color: #f8f8f8;
				color: $headings-color;

				.sidebar-item-icon {
					color: $primary !important;
				}

				.sidebar-item-number {
					opacity: 1;
				}
			}
		}


		.sticky-area {
			background-color: $white !important;
			@include media-breakpoint-up(xl) {
				position: relative;
				width: 320px;
				height: 100vh !important;
				overflow-y: auto;
				transform: translate(0, 0) !important;
			}
			@include media-breakpoint-down(lg) {
				width: 100%;
			}
		}

		.navbar-collapse {
			@include media-breakpoint-down(lg) {
				max-height: 500px;
				overflow-y: auto;
			}
		}
	}

	.page-content {
		width: calc(100% - 320px);
		max-width: 100%;
		flex: 1 1 auto;
		@include media-breakpoint-down(lg) {
			width: 100%;
			flex: 1 1 100%;
		}
	}

	.new-property-step {
		.nav-link {
			color: $body-color;
			border: 1px solid transparent;

			&.active, &:hover {
				color: $primary;
				background-color: $white !important;
				border-color: #e7e7e7;

			}
		}
	}
}

.upload-file {
	border: 3px dashed #e7e7e7;
	border-radius: 3px;

	.upload-icon {
		font-size: 85px;
		color: #eeeeee;
	}

	.dz-image {
		margin: auto;
		overflow: visible !important;
	}
}

.table.dataTable thead th {
	border-bottom: none;
	border-top: none;
	padding: 0.75rem;
	font-weight: normal;
}

.add-new-invoice {
	@include media-breakpoint-down(sm) {
		.item-table {
			thead {
				display: none;
			}

			td {
				border: none;
				padding-left: 7px;
				padding-right: 7px;
				vertical-align: middle;
			}

			tr:not(:first-child) .delete-item-row {
				border-top: 1px solid $border-color;
			}
		}
	}
}