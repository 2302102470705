.bottom-bar-action {
	box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.24);

}

.bottom-bar-action-01 {
	img {
		width: 56px;
		height: 56px;
	}
}